import { Footer } from "@bay1/ui";
import { MarketingNav } from "@bay1/ui/components/MarketingNav";
import Link from "next/link";
import { useCallback, useState } from "react";

import { formium } from "../lib/formium";

// pages/404.js
export default function Custom404(): JSX.Element {
  const [, setModalProperties] = useState({
    isVisible: false,
    headline: "",
    message: "",
  });

  const handleNewsletterFormSuccess = useCallback(async () => {
    setModalProperties({
      isVisible: true,
      headline: "Thanks for signing up",
      message: "The Highnote team will be in touch.",
    });
  }, []);

  return (
    <>
      <MarketingNav backgroundClass="bg-transparent" />

      <div className="to-ash min-h-screen bg-gradient-to-b from-white px-4 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main>
            <p className="from-green to-yellow bg-gradient-to-b bg-clip-text text-7xl font-bold text-transparent">
              404
            </p>
            <h1 className="text-xl">Page not found</h1>
            <p className="mt-1 text-sm">
              We couldn&#39;t find the page you&#39;re looking for.
            </p>
            <div className="mt-4 flex space-x-3">
              <Link href="/" className="link">
                Go back home<span aria-hidden="true"> →</span>
              </Link>
            </div>
          </main>
        </div>
      </div>
      {/* Footer */}
      <Footer
        formiumClient={formium}
        onNewsletterFormSuccess={handleNewsletterFormSuccess}
      />
    </>
  );
}
